<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import Multiselect from "vue-multiselect";
//import DatePicker from "vue-datepicker";
//import { MazPhoneNumberInput } from "maz-ui";
//import moment from "moment";
//import { format } from "date-fns";
import Vue from "vue";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Ajouter un véhicule",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Ajouter un vehicule",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],
      motorisation: ["ESSENCE", "GASOIL", "ELECTRIQUE", "HYBRIDE"],
      climatisation: ["OUI", "NON"],
      boite: ["AUTO", "MANUELLE"],
      statut: ["DISPO", "LOUER", "PANNE"],
      type_vehicule: ["BERLINE", "BUS", "4X4", "UTILITAIRE"],
      place: {
        nom: "",
        longitude: "",
        latitude: "",
      },
      kmAvantVidange: "",
      anneemiseencirculation: "",
      nbrePlace: "",
      couleur: "",
      lieu: "",
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showmap: false,
      coordinates: null,
      successShow: false,

      //Model of form
      marque: null,
      model: null,
      face: null,
      interieur: null,
      vueInterieur: null,
      arriere: null,
      typevehiculeSelect: null,
      kilometrageSelect: null,
      nombreplaceSelect: null,
      immatriculation: "",
      numeroAssurance: "",
      assurance: "",
      observations: "",
      prixPublieJournalier: 0,
      prixPublieJournalierHorsVille: 0,
      motorisationSelect: null,
      climatisationSelect: null,
      boiteSelect: null,
      statutSelect: null,
      chauffeurSelected: null,
      chauffeurs: [],
    };
  },
  validations: {},

  async mounted() {
    this.init();
  },

  methods: {
    async init() {
      const dataChauffeur = await apiRequest(
        "GET",
        "admin/entreprise/chauffeur",
        undefined,
        false
      );
      if (dataChauffeur && dataChauffeur.data) {
        //console.log("data.data:", dataChauffeur.data);
        const formattedTableC = dataChauffeur.data.chauffeurActif.map(
          (user) => {
            return {
              id: user.id,
              nom: user.nom + " " + user.prenom,
              contact: user.contact,
            };
          }
        );
        this.chauffeurs = formattedTableC;
      }
    },
    async addvehicule() {

      let haveClim = false;
      if (this.climatisationSelect == "OUI") {
        haveClim = true;
      }
      const add = await apiRequest("POST", "admin/entreprise/location", {
        types: this.typevehiculeSelect,
        marque: this.marque,
        model: this.model,
        motorisation: this.motorisationSelect,
        climatisation: haveClim,
        boite: this.boiteSelect,
        kmAuCompteur: this.kilometrageSelect,
        kmAvantVidange: this.kmAvantVidange,
        anneeMiseEnCirculation: this.anneemiseencirculation,
        nombreSieges: this.nbrePlace,
        couleur: this.couleur,
        immatriculation: this.immatriculation,
        lieu: this.place,
        prixPublieJournalier: this.prixPublieJournalier,
        prixPublieJournalierHorsVille: this.prixPublieJournalierHorsVille,
        compagnieAssurance: this.assurance,
        numeroAssurance: this.numeroAssurance,
        observations: this.observations,
        chauffeur: this.chauffeurSelected.id !=null? this.chauffeurSelected.id:null,
      });

      if (add && add.data && add.data.vehicule) {
        console.log("add.data:", add.data.vehicule);
        //ENVOIE D'IMAGE
        this.addPhotoEnFace(add.data.vehicule.id),
          this.addPhotoInterieur(add.data.vehicule.id),
          this.addPhotoVueInterieur(add.data.vehicule.id),
          this.addPhotoArriere(add.data.vehicule.id);
        this.successShow = true;
      } 
    },
    async addPhotoEnFace(id) {
      if (this.face) {
        let formData = new FormData();
        formData.append("idVehicule", id);
        formData.append("image", this.face);
        const returnPhotoEnFace = apiRequest(
          "POST",
          "admin/entreprise/location/add-image",
          formData,
          true
        );
        if (returnPhotoEnFace && returnPhotoEnFace.data) {
          console.log("Photo en face:", returnPhotoEnFace);
        }
      }
    },
    async addPhotoInterieur(id) {
      if (this.interieur) {
        let formData = new FormData();
        formData.append("idVehicule", id);
        formData.append("image", this.interieur);
        const returnPhotoInterieur = apiRequest(
          "POST",
          "admin/entreprise/location/add-image",
          formData,
          true
        );
        if (returnPhotoInterieur && returnPhotoInterieur.data) {
          console.log("Photo en face:", returnPhotoInterieur);
        }
      }
    },
    async addPhotoVueInterieur(id) {
      if (this.vueInterieur) {
        let formData = new FormData();
        formData.append("idVehicule", id);
        formData.append("image", this.vueInterieur);
        const returnPhotoVueInterieur = apiRequest(
          "POST",
          "admin/entreprise/location/add-image",
          formData,
          true
        );
        if (returnPhotoVueInterieur && returnPhotoVueInterieur.data) {
          console.log("Photo en face:", returnPhotoVueInterieur);
        }
      }
    },
    async addPhotoArriere(id) {
      if (this.arriere) {
        let formData = new FormData();
        formData.append("idVehicule", id);
        formData.append("image", this.arriere);
        const returnPhotoArriere = apiRequest(
          "POST",
          "admin/entreprise/location/add-image",
          formData,
          true
        );
        if (returnPhotoArriere && returnPhotoArriere.data) {
          console.log("Photo en face:", returnPhotoArriere);
        }
      }
    },

    handleFilevehiculeFace(event) {
   
      this.face = event.target.files[0];
      console.log(this.face );
    },

    handleFilevehiculeInterieur(event) {

      this.interieur = event.target.files[0]
    },
    
    handleFilevehiculeVue(event) {
   
      this.interieur = event.target.files[0]
    },

    onChange(event) {

      this.arriere = event.target.files[0]
    },




    setPlace: function(place) {
      if (place) {
        this.place.nom = place.formatted_address;
        this.place.latitude = place.geometry.location.lat();
        this.place.longitude = place.geometry.location.lng();
      }
      console.log("this.place:", this.place);
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },

    ModifFileUpload() {
      this.donImage = this.$refs.image.files[0];
    },
    
    positionAdd() {
      Vue.swal({
        positionAdd: "center-end",
        icon: "success",
        title: "Ajout éffectué avec succès",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée avec succès!!",
        showConfirmButton: false,
        timer: 3000,
      });
    },

    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      //console.log("this.$v:", this.$v);
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      //this.create();
    },

    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    displayMaps(place) {
      //this.showmap = true;
      place.geometry.location.lng();
      console.log(place.geometry.location.lng());
    },
    displayMap() {
      this.showmap = true;
    },




  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <form enctype="multipart/form-data" @submit.prevent="submitCar">
              <div class="row">
                <div class="col-md-6">
                  <label for="">Type de véhicule</label>
                  <multiselect
                    v-model="typevehiculeSelect"
                    :options="type_vehicule"
                    :close-on-select="true"
                  ></multiselect>
                </div>
                <div class="col-md-6">
                  <label for="">Marque</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="marque"
                    id="marque"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-6">
                  <label for="">Modèle</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="model"
                    id="model"
                  />
                </div>
                <div class="col-md-6">
                  <label for="">Motorisation</label>
                  <multiselect
                    v-model="motorisationSelect"
                    :options="motorisation"
                    :close-on-select="true"
                  ></multiselect>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-6">
                  <label for="">Climatisation</label>
                  <!-- <input type="text" class="form-control" id="" /> -->
                  <multiselect
                    v-model="climatisationSelect"
                    :options="climatisation"
                    :close-on-select="true"
                  ></multiselect>
                </div>
                <div class="col-md-6">
                  <label for="boite">Boîte</label>
                  <multiselect
                    v-model="boiteSelect"
                    :options="boite"
                    :close-on-select="true"
                  ></multiselect>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-6">
                  <label for="kilometrage">Kilométrage au compteur</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="kilometrageSelect"
                  />
                </div>
                <div class="col-md-6">
                  <label for="kmAvantVidange"
                    >Nombre kilométrage avant vidange</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    v-model="kmAvantVidange"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <label>Année de mise en circulation</label> 
                  <input type="text" class="form-control" v-model="anneemiseencirculation" />
                </div>
                <div class="col-md-6">
                  <label>Nombre de place</label>
                  <input
                    type="number"
                    id="nbrePlace"
                    class="form-control"
                    v-model="nbrePlace"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-6">
                  <label>Couleur</label>
                  <input
                    type="text"
                    id="couleur"
                    class="form-control"
                    v-model="couleur"
                  />
                </div>
                <div class="col-md-6">
                  <label>Lieu</label>
                  <gmap-autocomplete
                    type="text"
                    class="form-control"
                    id=""
                    placeholder="Emplacement véhicule"
                    @place_changed="setPlace"
                    :select-first-on-enter="true"
                  >
                  </gmap-autocomplete>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-6">
                  <label>Immatriculation</label>
                  <input
                    type="text"
                    id="immatriculation"
                    class="form-control"
                    v-model="immatriculation"
                  />
                </div>
                <div class="col-md-6">
                  <label>Chauffeur</label>
                  <multiselect
                    v-model="chauffeurSelected"
                    :options="chauffeurs"
                    label="nom"
                    track-by="id"
                  ></multiselect>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-6">
                  <label>Nom de la compagnie d'assurance</label>
                  <input
                    type="text"
                    class="form-control"
                    id="assurance"
                    v-model="assurance"
                  />
                </div>
                <div class="col-md-6">
                  <label>Numéro de l'assurance</label>
                  <input
                    type="text"
                    id="numeroAssurance"
                    class="form-control"
                    v-model="numeroAssurance"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div>
              <label for="statut">Statut du véhicule</label>
              <multiselect
                v-model="statutSelect"
                :options="statut"
              ></multiselect>
            </div>
            <div class="row mt-2">
              <div class="col-md-6">
                <label>Prix/J </label>
                <input
                  type="number"
                  id="prixJournalier"
                  class="form-control"
                  v-model="prixPublieJournalier"
                />
              </div>
              <div class="col-md-6">
                <label>Prix/J Hors Ville</label>
                <input
                  type="number"
                  id="prixJournalier"
                  class="form-control"
                  v-model="prixPublieJournalierHorsVille"
                />
              </div>
            </div>
            <div>
              <label for="observations">Observations</label>
              <textarea
                class="form-control"
                id="observations"
                cols="30"
                rows="3"
                v-model="observations"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label>Photo 1: véhicule vue de face</label>
                <input
                  type="file"
                  id="face"
                  ref="face"
                  @change="handleFilevehiculeFace($event)"
                  class="form-control"
                />
              </div>
              <div class="col-md-6">
                <label>Photo 2: véhicule vue d'intérieur</label>
                <input
                  type="file"
                  ref="interieur"
                  class="form-control"
                  id="interieur"
                  @change="handleFilevehiculeInterieur($event)"
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <label>Photo 3: véhicule vue d'intérieur</label>
                <input
                  type="file"
                  ref="vueInterieur"
                  class="form-control"
                  id="vueInterieur"
                  @change="handleFilevehiculeVue($event)"
                />
              </div>
              <div class="col-md-6">
                <label>Photo 4: véhicule vue d'arrière</label>
                <input
                  type="file"
                  ref="arriere"
                  class="form-control"
                  id="arriere"
                  @change="onChange($event)"
                />
              </div>
            </div>
            <div class="form-group mt-4">
              <button class="btn btn-primary float-right" @click="addvehicule">
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="successShow"
      title="Succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="success" @click="successShow = false">OK</b-button>
      </div>
    </b-modal>
  </Layout>
</template>

<style>
#showPassword {
  width: 35px;
  height: 35px;
}
</style>
